function HeaderNav(props) {
    let navigaion=props.navValue;
    
    return(
        <div className="navigation-container">
            {
                navigaion.map((nav,index)=>(
                    <span className="nav-item" key={index}>{nav}</span>
                ))
            }
        </div>
    );
}
export default HeaderNav;