import '../../../css/register.css';
import '../../../css/formcontrol.css';
import { Outlet } from 'react-router-dom';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import { useSelector } from 'react-redux';
import LoaddingRound from '../../LoadingRound';

function Register() {
    const { loading, error } = useSelector((state) => state.customer);
    return (
        <>
            <Header />
            <div className="total-body myRow bg_lightgray">
                <div className="register-cont myRow">
                    <div className="col-md-6 register-details">
                        <div className="cont-details">
                            <div className="heading-cont-reg">Join With Us</div>
                            <div className="body-cont">
                                <div className="col-details-cont"><span className='stepNumber'>Step 1 </span> Fill Your Own Informations</div>
                                <div className="col-details-cont"><span className='stepNumber'>Step 2</span> Fill Your Residential Informations</div>
                                <div className="col-details-cont"><span className='stepNumber'>Step 3</span> Fill Your Contact Informations</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 register-form myRow">
                        <div className="heading-cont-reg">Sign Up</div>
                        <Outlet></Outlet>
                    </div>
                </div>
                <Footer />
            </div>
            {
                loading ? <LoaddingRound /> : ""
            }
        </>
    )
}
export default Register