import '../../css/header.css';
import logo from '../../img/icon/logo.jpg';
import { Link } from "react-router-dom";
function Header() {
    return (
        <>
            <div className="top-header">
                <div className="logo">
                    <a href="\">
                        <img src={logo} />
                    </a>
                </div>
                <div className="menu">
                    <div className="my-nav">
                        <ul className="glv_1">
                            <li> <a>Home</a></li>
                            <li> <a>Partners</a></li>
                            <li> <a>Associates <i className="fas fa-chevron-down"></i></a></li>
                            <li> <a>Blogs</a></li>
                            <li> <a>All Services <i className="fas fa-chevron-down"></i></a></li>
                            <li> <Link to="/customer/login">Member’s Area <i className="fas fa-chevron-down"></i></Link></li>
                            <li> <a>Reviews</a></li>
                            <li> <a>About Us</a></li>
                            <li> <a>Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Header