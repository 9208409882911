import TopNav from "../../layout/TopNav";
import '../../../../css/customerlayout.css';
import '../../../../css/visitrequest.css';
import SideMenu from "../../layout/SideMenu";
import React, { useState } from "react";
import HeaderNav from "../../layout/HeaderNav";
import { getallDraftRequest, getallValidRequest, updateStatusVisitRequest } from "../../../../redux/visitRequest";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

function VisitRequest() {

    const dispatch = useDispatch();

    const [validRequests, setValidRequest] = useState([]);
    const [draftRequests, setDraftRequest] = useState([]);
    let customer_id = JSON.parse(localStorage.getItem('customer-info')).customer_id;
    let status;

    React.useEffect(() => {
        refreshTable();
    }, []);

    function refreshTable(){
        dispatch(getallValidRequest(customer_id)).then((result) => {
            if (result.payload.error === "") {
                setValidRequest(result.payload.data);
            }
        });
        dispatch(getallDraftRequest(customer_id)).then((result) => {
            if (result.payload.error === "") {
                setDraftRequest(result.payload.data);
            }
        });
    }
    function capitalEachword(mySentence) {
        const words = mySentence.split(" ");
        for (let i = 0; i < words.length; i++)
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        return words.join(" ");
    }

    function updateRequestStatus(visit_id,visit_status) {
        dispatch(updateStatusVisitRequest({visit_id,visit_status})).then((result) => {            
            if (result.payload.error == "") {
                refreshTable();
            }
        });
    };

    return (
        <>
            <div className="totalbody">
                <div className="left-sideSection">
                    <SideMenu Options="Visit Request" Suboptions="" />
                </div>
                <div className="right-sideSection">
                    <TopNav />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Visit Request"]} />
                        <div className="fulldetailstable">
                            <div className="button-container">
                                <Link to="/customer/visit-request/new" className="my-button bg-lightgreen"><i className="fas fa-plus"></i> New Request</Link>
                            </div>
                            <div className="table-heading">Visit Requests</div>
                            <table className="table table-striped my-table">
                                <thead>
                                    <tr className="table-success">
                                        <th></th>
                                        <th>Request ID</th>
                                        <th>Request For</th>
                                        <th>Emergency</th>
                                        <th>Visit Date</th>
                                        <th>Visit Time</th>
                                        <th>Service Type</th>
                                        <th>Status</th>
                                        <th>Prefered Associate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (validRequests != "") ?
                                            validRequests.map((validRequest,index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <Link to={"/customer/visit-request/edit/" + validRequest.vrid} title="Edit Request"><i className="far fa-edit"></i></Link>
                                                            <i className="fas fa-ban" title="Cancel Request" onClick={(e) => updateRequestStatus(validRequest.vrid,"cancelled")}></i>
                                                        </td>
                                                        <td>{validRequest.vrid}</td>
                                                        <td>{validRequest.request_for}</td>
                                                        <td>{validRequest.emergency}</td>
                                                        <td>{validRequest.visit_date}</td>
                                                        <td>{validRequest.visit_time}</td>
                                                        <td>{validRequest.servicetype}</td>
                                                        <td>{capitalEachword(validRequest.status)}</td>
                                                        <td>{validRequest.prefered_asso}</td>
                                                    </tr>
                                            ) : <tr><td colSpan="9"><div className="no-data">No Request Found</div></td></tr>
                                    }
                                </tbody>
                            </table>
                            <div className="table-heading">Draft Requests</div>
                            <table className="table table-striped my-table">
                                <thead>
                                    <tr className="table-warning">
                                        <th></th>
                                        <th>Request ID</th>
                                        <th>Request For</th>
                                        <th>Emergency</th>
                                        <th>Visit Date</th>
                                        <th>Visit Time</th>
                                        <th>Service Type</th>
                                        <th>Prefered Associate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (draftRequests != "") ?
                                            draftRequests.map((draftRequest, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <Link to={"/customer/visit-request/edit/" + draftRequest.vrid} title="Edit Request"><i className="far fa-edit"></i></Link>
                                                            <i className="fas fa-trash" title="Cancel Request" onClick={(e) => updateRequestStatus(draftRequest.vrid,"deleted")}></i>
                                                        </td>
                                                        <td>{draftRequest.vrid}</td>
                                                        <td>{draftRequest.request_for}</td>
                                                        <td>{draftRequest.emergency}</td>
                                                        <td>{draftRequest.visit_date}</td>
                                                        <td>{draftRequest.visit_time}</td>
                                                        <td>{draftRequest.servicetype}</td>
                                                        <td>{draftRequest.prefered_asso}</td>
                                                    </tr>
                                            ) : <tr><td colSpan="8"><div className="no-data">No Request Found</div></td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default VisitRequest;