import TopNav from "../../layout/TopNav";
import '../../../../css/customerlayout.css';
import '../../../../css/visitrequest.css';
import '../../../../css/mainformcontrol.css';
import SideMenu from "../../layout/SideMenu";
import { useState } from "react";
import HeaderNav from "../../layout/HeaderNav";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { newVisitRequest } from "../../../../redux/visitRequest";

function NewVisitRequest() {

    const [requestFor, setRequestFor] = useState("");
    const [emergency, setEmergency] = useState("no");
    const [visitDate, setVisitDate] = useState("");
    const [visitTime, setVisitTime] = useState("");
    const [serviceType, setServiceType] = useState("");
    const [preferedAsso, setPreferedAsso] = useState("");
    const [preferedAssoid, setPreferedAssoId] = useState("0");
    const [requestDetails, setRequestDetails] = useState("");
    const [additinalNote, setAdditinalNote] = useState("");
    let status;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function blankRequest(event) {
        event.preventDefault();       
    };
    function setCheckBox(event){
        if(event.target.checked == true){
            setEmergency("yes");
        }else{
            setEmergency("no");
        }
    }
    function newRequest(){
        status="in progress";
        visitRequest();
    };
    function draftRequest(){
        status="draft";
        visitRequest();
    };
    function visitRequest(){
        let customer_id = JSON.parse(localStorage.getItem('customer-info')).customer_id;
        let visitrequestData = { requestFor, emergency, visitDate, visitTime, serviceType, preferedAsso, requestDetails, additinalNote, status, preferedAssoid };
        dispatch(newVisitRequest({customer_id,visitrequestData})).then((result) => {            
            if (result.payload.error == "") {
                navigate("/customer/visit-request");
            }
        });
    }

    return (
        <>
            <div className="totalbody">
                <div className="left-sideSection">
                    <SideMenu Options="Visit Request" Suboptions="New Request" />
                </div>
                <div className="right-sideSection">
                    <TopNav />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Visit Request", "New Request"]}/>
                        <div className="fullformdetails big-panel">
                            <form id="addnewRequest" onSubmit={(event) => blankRequest(event)}>
                                <div className="input-formtitle">
                                    Create a New Visit Request
                                </div>
                                <div className="myRow">
                                    <div className="col-md-5 offset-md-1">
                                        <div className="input-formgroup">
                                            <label className="input-label">Request For</label>
                                            <input className="input-cell" required="required" id="requestfor" type="text" placeholder="Request For" onChange={(e) => setRequestFor(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow">
                                    <div className="col-md-5 offset-md-1">
                                        <div className="input-formgroup">
                                            <label className="input-label">Is this an Emergency</label>
                                            <label className="switch">
                                                <input type="checkbox" onClick={(e) => setCheckBox(e)} id="emergency" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow justify-content-md-center">
                                    <div className="col-md-5">
                                        <div className="input-formgroup">
                                            <label className="input-label">Visit Date</label>
                                            <input className="input-cell" required="required" id="visitdate" type="date" placeholder="Visit Date" onChange={(e) => setVisitDate(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="input-formgroup">
                                            <label className="input-label">Visit Time</label>
                                            <input className="input-cell" required="required" id="visittime" type="time" placeholder="Visit Time" onChange={(e) => setVisitTime(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow justify-content-md-center">
                                    <div className="col-md-5">
                                        <div className="input-formgroup">
                                            <label className="input-label">Service Types</label>
                                            <input className="input-cell" required="required" id="servicetype" type="text" placeholder="Service Type" onChange={(e) => setServiceType(e.target.value)} />
                                        </div>
                                    </div> 
                                    <div className="col-md-5">
                                        <div className="input-formgroup">
                                            <label className="input-label">Preferred Associate</label>
                                            <input className="input-cell" id="prefredasso" type="text" placeholder="Preferred Associate" onChange={(e) => setPreferedAsso(e.target.value)} />
                                        </div>
                                    </div>                                   
                                </div>
                                <div className="myRow justify-content-md-center">
                                    <div className="col-md-5">
                                        <div className="input-formgroup">
                                            <label className="input-label">Request Details</label>
                                            <textarea className="input-cell" required="required" id="requestdetails" type="text" placeholder="Request Details" onChange={(e) => setRequestDetails(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="input-formgroup">
                                            <label className="input-label">Additional Notes</label>
                                            <textarea className="input-cell" required="required" id="addnotes" type="text" placeholder="Additional Notes" onChange={(e) => setAdditinalNote(e.target.value)} />
                                        </div>
                                    </div>                                      
                                </div>
                                <div className="myRow justify-content-md-end">
                                    <div className="col-md-2">
                                        <input type="button" value="Create" className="my-button bg-green" onClick={(e) => newRequest(e.target.value)}/>
                                    </div>
                                    <div className="col-md-2">
                                        <input type="button" value="Save as Draft" className="my-button bg-yellow" onClick={(e) => draftRequest(e.target.value)}/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NewVisitRequest;