import TopNav from "../layout/TopNav";
import '../../../css/customerlayout.css';
import SideMenu from "../layout/SideMenu";

function Dashboard() {
    
    return (
        <>
            <div className="totalbody">
                <div className="left-sideSection">
                    <SideMenu Options="Dashboard" Suboptions=""/>
                </div>
                <div className="right-sideSection">
                    <TopNav />
                    <div className="mainbody-container">
                        
                    </div>
                </div>
            </div>
        </>
    )
}
export default Dashboard;