import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"

function ProtectedCustomer(props) {
    let CMP = props.components;
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const checkUserToken = () => {
        const userToken = localStorage.getItem('customer-info');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/customer/login');
        }
        setIsLoggedIn(true);
    }
    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);
    return (
        <>
            {
                isLoggedIn ? <CMP /> : null
            }
        </>
    )
}
export default ProtectedCustomer;