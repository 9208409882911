import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContactCustomer } from '../../redux/customerAuth';
import loadinggif from '../../img/tele.gif'
function ContactCustomer(props) {

    const [contacts, setContacts] = useState([]);
    const { loading } = useSelector((state) => state.customer);
    const dispatch = useDispatch();

    let id = "";

    if (localStorage.getItem('customer-info')) {
        id = JSON.parse(localStorage.getItem('customer-info')).customer_id;
    }

    React.useEffect(() => {
        dispatch(getContactCustomer(id)).then((result) => {
            if (result.payload.error === "") {
                setContacts(result.payload.data);
            }
        });
    }, []);

    return (
        <>
            <div className="myRow contact-container justify-content-between">
                <div className="heading-contact">Contacts</div>
                {
                    (contacts !== "") ?
                        contacts.map((contact) =>
                            <div className="contact-card" key={contact.id}>
                                <div className="contact-type">{contact.contact_type}</div>
                                <div className="contact-phone"><i className="fas fa-phone-alt"></i> {contact.phone}</div>
                                <div className="contact-email"><i className="fas fa-at"></i> {contact.email}</div>
                                <div className="contact-whatsapp"><i className="fab fa-whatsapp"></i> {contact.whatsapp}</div>
                            </div>
                        ) : <div className="no-data">No Contact Added</div>
                }                
                {
                    loading ?
                        <div className='grayscreenload'>
                            <div className='loadinggif'>
                                <img src={loadinggif} alt="" />
                            </div>
                        </div> : ""
                }
            </div>
        </>
    )

}
export default ContactCustomer