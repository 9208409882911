import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverHost } from "../Store/config";

export const newVisitRequest = createAsyncThunk(
    'customer/visit-request/new',
    async (yourData) => {
        let id = yourData.customer_id;
        let visitRequestDatas = yourData.visitrequestData;
        let result = await fetch(serverHost + "api/customer/visitrequest/add/" + id, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(visitRequestDatas)
        })
        result = await result.json();
        return result;
    });

export const getallValidRequest = createAsyncThunk(
    'customer/visit-request/valid',
    async (customer_id) => {
        let result = await fetch(serverHost + "api/customer/visitrequest/valid/" + customer_id, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getallDraftRequest = createAsyncThunk(
    'customer/visit-request/draft',
    async (customer_id) => {
        let result = await fetch(serverHost + "api/customer/visitrequest/draft/" + customer_id, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getVisitRequest = createAsyncThunk(
    'customer/visit-request/getsingle',
    async (visit_id) => {
        let result = await fetch(serverHost + "api/customer/visitrequest/get/" + visit_id, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const updateVisitRequest = createAsyncThunk(
    'customer/visit-request/update',
    async (yourData) => {
        let vid = yourData.visit_id;
        let visitdata = yourData.visitrequestData;
        let result = await fetch(serverHost + "api/customer/visitrequest/update/" + vid, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(visitdata)
        })
        result = await result.json();
        return result;
    });
export const updateStatusVisitRequest = createAsyncThunk(
    'customer/visit-request/statusupdate',
    async (yourData) => {
        let vid = yourData.visit_id;
        let visitstatus = yourData.visit_status;
        let result = await fetch(serverHost + "api/customer/visitrequest/updatestatus/" + vid + "/" + visitstatus, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });

const visitRequestSlice = createSlice({
    name: 'visitRequest',
    initialState: {
        loading: false,
        requestData: null,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(newVisitRequest.pending, (state) => {
                state.loading = true;
                state.requestData = null;
                state.error = null;
            })
            .addCase(newVisitRequest.fulfilled, (state, action) => {
                state.loading = false;
                state.requestData = action.payload;
                state.error = null;
            })
            .addCase(newVisitRequest.rejected, (state, action) => {
                state.loading = false;
                state.requestData = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getallValidRequest.pending, (state) => {
                state.loading = true;
                state.requestData = null;
                state.error = null;
            })
            .addCase(getallValidRequest.fulfilled, (state, action) => {
                state.loading = false;
                state.requestData = action.payload;
                state.error = null;
            })
            .addCase(getallValidRequest.rejected, (state, action) => {
                state.loading = false;
                state.requestData = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getallDraftRequest.pending, (state) => {
                state.loading = true;
                state.requestData = null;
                state.error = null;
            })
            .addCase(getallDraftRequest.fulfilled, (state, action) => {
                state.loading = false;
                state.requestData = action.payload;
                state.error = null;
            })
            .addCase(getallDraftRequest.rejected, (state, action) => {
                state.loading = false;
                state.requestData = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getVisitRequest.pending, (state) => {
                state.loading = true;
                state.requestData = null;
                state.error = null;
            })
            .addCase(getVisitRequest.fulfilled, (state, action) => {
                state.loading = false;
                state.requestData = action.payload;
                state.error = null;
            })
            .addCase(getVisitRequest.rejected, (state, action) => {
                state.loading = false;
                state.requestData = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updateVisitRequest.pending, (state) => {
                state.loading = true;
                state.requestData = null;
                state.error = null;
            })
            .addCase(updateVisitRequest.fulfilled, (state, action) => {
                state.loading = false;
                state.requestData = action.payload;
                state.error = null;
            })
            .addCase(updateVisitRequest.rejected, (state, action) => {
                state.loading = false;
                state.requestData = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updateStatusVisitRequest.pending, (state) => {
                state.loading = true;
                state.requestData = null;
                state.error = null;
            })
            .addCase(updateStatusVisitRequest.fulfilled, (state, action) => {
                state.loading = false;
                state.requestData = action.payload;
                state.error = null;
            })
            .addCase(updateStatusVisitRequest.rejected, (state, action) => {
                state.loading = false;
                state.requestData = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
    }
});
export default visitRequestSlice.reducer;