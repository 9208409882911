import { useDispatch, useSelector } from "react-redux";
import RegStep from "./RegStep"
import { addContactCustomer } from "../../../redux/customerAuth";
import { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import ContactCustomer from "../../contact/ContactCustomer";

function RegisterStep3() {

    const [contact_type,setContactType]=useState("");
    const [phone,setPhone]=useState("");
    const [email,setEmail]=useState("");
    const [whatsapp,setWhatsapp]=useState("");
    const [seed, setSeed] = useState(1);
    let id="";

    const dispatch=useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        if(localStorage.getItem('customer-info')){
            if(JSON.parse(localStorage.getItem('customer-info')).fladdress){                
                id=JSON.parse(localStorage.getItem('customer-info')).customer_id;                
            }else{
                navigate("/customer/register/step2");
            }
        }else{
            navigate("/customer/register/step1");
        }
    });    

    function addContact(event) {
        event.preventDefault();
        let cid=id;
        let contactData = { contact_type, phone, email, whatsapp };        
        dispatch(addContactCustomer({cid,contactData})).then((result) => {
            if (result.payload.error == "") {
                document.getElementById("addContact").reset();
                setSeed(Math.random());
            }
        });        
    };
    
    const viewProfile = () =>{
        navigate("/customer/dashboard");
    }

    return (
        <>
            <RegStep StepValue={3}/>
            <div className="regform-cont">
                <form id="addContact" onSubmit={(event) => addContact(event)}>
                <div className='myRow inputrow'>
                        <div className='col-md-6'>
                        <div className="form__group field">
                                <select className="form__field" onChange={(e) => setContactType(e.target.value)} id="contacttype" required defaultValue={""}>
                                    <option value="" disabled >Select any one</option>
                                    <option value="Regular">Regular</option>
                                    <option value="Emergency">Emergency</option>
                                </select>
                                <label className="form__label">Contact Type</label>
                            </div>
                        </div>
                    </div>
                    <div className='myRow inputrow'>
                        <div className='col-md-6'>
                            <div className="form__group field">
                                <input type="text" className="form__field" placeholder="Enter Mobile Number" onChange={(e) => setPhone(e.target.value)} id="mobilenumber" required />
                                <label className="form__label">Mobile Number</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form__group field">
                                <input type="text" className="form__field" placeholder="Enter Whatsapp Number" onChange={(e) => setWhatsapp(e.target.value)} id="wpnumber" />
                                <label className="form__label">Whatsapp Number</label>
                            </div>
                        </div>
                    </div> 
                    <div className='myRow inputrow'>
                        <div className='col-md-12'>
                            <div className="form__group field">
                                <input type="email" className="form__field" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} id="email" required />
                                <label className="form__label">Email</label>
                            </div>
                        </div>
                    </div>                    
                    
                    <div className='myRow inputrow'>
                        <div className='col-md-6'>
                            <button type="submit" className="mynextbtn btn btn-primary">Add New</button>
                        </div>                       
                    </div>
                    <div className='myRow inputrow'>                        
                        <div className='col-md-12'>
                            <button type="button" className="myprofilebtn btn btn-secondary" onClick={viewProfile}>Visit Profile</button>
                        </div>
                    </div>

                </form>
                <ContactCustomer key={seed}/>
            </div>
        </>
    )
}
export default RegisterStep3