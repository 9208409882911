import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Register from './component/customer/register/Register';
import Login from './component/customer/login/Login';
import RegisterStep1 from './component/customer/register/RegisterStep1';
import RegisterStep2 from './component/customer/register/RegisterStep2';
import RegisterStep3 from './component/customer/register/RegisterStep3';
import Dashboard from './component/customer/features/Dashboard';
import ProtectedCustomer from './component/customer/ProtectedCustomer';
import Home from './Home';
import VisitRequest from './component/customer/features/VisitRequest/VisitRequest';
import NewVisitRequest from './component/customer/features/VisitRequest/NewVisitRequest';
import DraftVisitRequest from './component/customer/features/VisitRequest/DraftVisitRequest';
import CustomerProfile from './component/customer/features/Profile/CustomerProfile';
import EditVisitRequest from './component/customer/features/VisitRequest/EditVisitRequest';


function App() {
  return (
    <>
      <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='customer/login' element={<Login/>} />
            <Route path='customer/register' element={<Register />}>
              <Route index path='step1' element={<RegisterStep1/>} />
              <Route path='step2' element={<RegisterStep2 />} />
              <Route path='step3' element={<RegisterStep3 />} />
            </Route>
            <Route path='customer/dashboard' element={<ProtectedCustomer components={Dashboard}/>} />
            <Route path='customer/visit-request' element={<ProtectedCustomer components={VisitRequest}/>} />
            <Route path='customer/visit-request/new' element={<ProtectedCustomer components={NewVisitRequest}/>} />
            <Route path='customer/visit-request/draft' element={<ProtectedCustomer components={DraftVisitRequest}/>} />
            <Route path='customer/profile' element={<ProtectedCustomer components={CustomerProfile}/>} />
            <Route path='customer/visit-request/edit/:vistid' element={<ProtectedCustomer components={EditVisitRequest}/>} />

          </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
