import SideMenu from "../../layout/SideMenu";
import TopNav from "../../layout/TopNav";
import HeaderNav from "../../layout/HeaderNav";
import '../../../../css/customerlayout.css';
import '../../../../css/customerprofile.css';
import { Link } from "react-router-dom";
import { man_1_rb, man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb, lady_1_rb, lady_2_rb, lady_3_rb } from '../../../../img/customeravatarimage';

function CustomerProfile() {
    const customeruser = JSON.parse(localStorage.getItem('customer-info'));
    const custMale = [man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb];
    const custFemale = [lady_1_rb, lady_2_rb, lady_3_rb];
    const custAll = [man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb, lady_1_rb, lady_2_rb, lady_3_rb];
    let profilePic;

    if (customeruser.gender == "male") {
        profilePic = custMale[Math.floor(Math.random() * custMale.length)];
    } else if (customeruser.gender == "female") {
        profilePic = custFemale[Math.floor(Math.random() * custFemale.length)];
    } else {
        profilePic = custAll[Math.floor(Math.random() * custAll.length)];
    }

    return (
        <>
            <div className="totalbody">
                <div className="left-sideSection">
                    <SideMenu Options="Profile" Suboptions="" />
                </div>
                <div className="right-sideSection">
                    <TopNav />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Your Profile"]} />
                        <div className="fulldetailstable">
                            <div className="button-container">
                                
                            </div>
                            <div className="myRow">
                                <div className="fullpofile-image">
                                    <img src={profilePic} alt="Profile Pic" />
                                    <Link to="/customer/profile/edit" className="my-button bg-lightgreen"><i className="fas fa-user-edit"></i> Edit Profile</Link>
                                </div>
                                <div className="profile-details">
                                    <div className="profile-item">
                                        <label>Name</label>
                                        {customeruser.fname} {customeruser.mname} {customeruser.lname}
                                    </div>
                                    <div className="profile-item">
                                        <label>Email</label>
                                        {customeruser.email}
                                    </div>
                                    <div className="myRow">
                                        <div className="col-md-6">
                                            <div className="profile-item">
                                                <label>Address</label>
                                                {customeruser.fladdress}<br/>
                                                {customeruser.sladdress}<br/>
                                                {customeruser.city}, {customeruser.state}<br/>
                                                Zip Code - {customeruser.zipcode}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="profile-item">
                                                <label>Country</label>
                                                {customeruser.country}
                                            </div>
                                            <div className="profile-item">
                                                <label>Locality  & Time Zone</label>
                                                {customeruser.locality} ( {customeruser.timezone} )
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CustomerProfile;