function RegStep(props) {
    let StepValue = props.StepValue;
    let outputvalue = "";
    const step2css = {
        width : "50%",
    }
    const step3css = {
        width : "100%"
    }
    if(StepValue==1){
        outputvalue =<>
            <div className="progress-bar">
                <span className="indicator"></span>
            </div>
            <span className="circle active">1</span>
            <span className="circle">2</span>
            <span className="circle">3</span>
        </>
    }
    else if(StepValue==2){
        outputvalue =<>
            <div className="progress-bar">
                <span className="indicator" style={step2css}></span>
            </div>
            <span className="circle active">1</span>
            <span className="circle active">2</span>
            <span className="circle">3</span>
        </>
    }
    else if(StepValue==3){
        outputvalue =<>
            <div className="progress-bar">
                <span className="indicator" style={step3css}></span>
            </div>
            <span className="circle active">1</span>
            <span className="circle active">2</span>
            <span className="circle active">3</span>
        </>
    }
    return (
        <>
            <div className="step-def">
                <div className="steps">
                    {
                     outputvalue
                    }
                </div>
            </div>
        </>
    )
}
export default RegStep