import loadingGif from '../img/loading-42.gif';
function LoaddingRound() {
    return (
        <div className='grayscreenload'>
            <div className='loadinggif'>
                <img src={loadingGif} alt="" />
            </div>
        </div>
    )
}
export default LoaddingRound;