import Footer from "./component/layout/Footer";
import Header from "./component/layout/Header";

function Home() {
    return (
        <>
            <Header />
            <div className="total-body myRow bg_lightgray">
                <div className="login-cont myRow"></div>
                <Footer />
            </div>
        </>
    )
}
export default Home;