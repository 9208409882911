import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../img/icon/logo-removebg.png';
function SideMenu(props) {
    let options = props.Options;
    let Suboptions = props.Suboptions;

    const navigate = useNavigate();

    function makeSubmenu(myOption, mlink, Active) {
        if (Active)
            return Suboptions === myOption ? <Link to={mlink}><div className="submenu-item selected"><i className="fas fa-chevron-right"></i> {myOption}</div></Link>
                : <Link to={mlink}><div className="submenu-item"><i className="fas fa-chevron-right"></i> {myOption}</div></Link>
        else
            return <Link to={mlink}><div className="submenu-item"><i className="fas fa-chevron-right"></i> {myOption}</div></Link>
    }
    function makeMainmenu(myOption, mlink, icon) {
        return options === myOption ? <Link to={mlink}><div className="menu-item selected">{icon} {myOption}</div></Link>
            : <Link to={mlink}><div className="menu-item">{icon} {myOption}</div></Link>
    }
    const logout = () => {
        localStorage.clear();
        navigate("/customer/login");
    }

    return (
        <>
            <div className="fullmenu">
                <div className="logo-menu">
                    <img src={logo} alt="logo" />
                </div>
                <div className="sidemenu-itemlist">
                    {makeMainmenu("Dashboard", "/customer/dashboard", <i className="fas fa-house-user"></i>)}
                    {options === "Visit Request"
                        ? <div className="menu-Submenu selected">
                            {makeMainmenu("Visit Request", "/customer/visit-request", <i className="fas fa-satellite-dish"></i>)}
                            {makeSubmenu("New Request", "/customer/visit-request/new", true)}
                            {makeSubmenu("Draft Request", "/customer/visit-request/draft", true)}
                        </div>
                        : <div className="menu-Submenu">
                            {makeMainmenu("Visit Request", "/customer/visit-request", <i className="fas fa-satellite-dish"></i>)}
                            {makeSubmenu("New Request", "/customer/visit-request/new", false)}
                            {makeSubmenu("Draft Request", "/customer/visit-request/draft", false)}
                        </div>
                    }
                    {options === "Invoice"
                        ? <div className="menu-Submenu selected">
                            {makeMainmenu("Invoice", "/customer/dashboard", <i className="fas fa-receipt"></i>)}
                            {makeSubmenu("Pay", "NewLink", true)}
                            {makeSubmenu("Check Update", "NewLink", true)}
                        </div>
                        : <div className="menu-Submenu">
                            {makeMainmenu("Invoice", "/customer/dashboard", <i className="fas fa-receipt"></i>)}
                            {makeSubmenu("Pay", "NewLink", false)}
                            {makeSubmenu("Check Update", "NewLink", false)}
                        </div>
                    }
                    {options === "Profile"
                        ? <div className="menu-Submenu selected">
                            {makeMainmenu("Profile", "/customer/profile", <i className="fas fa-user-alt"></i>)}
                            {makeSubmenu("Contacts", "NewLink", true)}
                            {makeSubmenu("Change Password", "NewLink", true)}
                            {makeSubmenu("Chat", "NewLink", true)}
                            <div className="submenu-item" onClick={logout}><i className="fas fa-chevron-right"></i> Logout</div>
                        </div>
                        : <div className="menu-Submenu">
                            {makeMainmenu("Profile", "/customer/profile", <i className="fas fa-user-alt"></i>)}
                            {makeSubmenu("Contacts", "NewLink", false)}
                            {makeSubmenu("Change Password", "NewLink", false)}
                            {makeSubmenu("Chat", "NewLink", false)}
                            <div className="submenu-item" onClick={logout}><i className="fas fa-chevron-right"></i> Logout</div>
                        </div>
                    }

                </div>
            </div>
        </>
    )
}
export default SideMenu;