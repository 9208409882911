import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverHost } from "../Store/config";

export const loginCustomer = createAsyncThunk(
    'customer/login',
    async (loginDatas) => {
        let result = await fetch(serverHost + "api/customer/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(loginDatas)
        })
        result = await result.json();
        if (result.error === "") {
            localStorage.setItem('customer-info', JSON.stringify(result.data));
        }
        return result;
    });
export const registerstep1Customer = createAsyncThunk(
    'customer/register/step1',
    async (regusterDatas) => {
        let result = await fetch(serverHost + "api/customer/register/step1", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(regusterDatas)
        })
        result = await result.json();
        if (result.error === "") {
            localStorage.setItem('customer-info', JSON.stringify(result.data));
        }
        return result;
    });
export const registerstep2Customer = createAsyncThunk(
    'customer/register/step2',
    async (yourData) => {
        let id = yourData.cid;
        let regusterDatas = yourData.regusterData;
        let result = await fetch(serverHost + "api/customer/register/step2/" + id, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(regusterDatas)
        })
        result = await result.json();
        if (result.error === "") {
            localStorage.setItem('customer-info', JSON.stringify(result.data));
        }
        return result;
    });
export const addContactCustomer = createAsyncThunk(
    'customer/register/step3',
    async (yourData) => {
        let id = yourData.cid;
        let contactDatas = yourData.contactData;
        let result = await fetch(serverHost + "api/customer/addcontact/" + id, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(contactDatas)
        })
        result = await result.json();
        return result;
    });
export const getContactCustomer = createAsyncThunk(
    "",
    async (id) => {
        let result = await fetch(serverHost + "api/customer/getcontact/" + id, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });

const customerSlice = createSlice({
    name: 'customerlogin',
    initialState: {
        loading: false,
        customer: null,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginCustomer.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(loginCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(loginCustomer.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(registerstep1Customer.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(registerstep1Customer.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(registerstep1Customer.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addContactCustomer.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(addContactCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(addContactCustomer.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getContactCustomer.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(getContactCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(getContactCustomer.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })

    }
});
export default customerSlice.reducer;