import { useDispatch, useSelector } from "react-redux";
import RegStep from "./RegStep"
import { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { registerstep2Customer } from "../../../redux/customerAuth";


function RegisterStep2() {
    
    const [addressFL,setAddressFL]=useState("");
    const [addressSL,setAddressSL]=useState("");
    const [city,setCity]=useState("");
    const [state,setState]=useState("");
    const [country,setCountry]=useState("");
    const [zipcode,setZipcode]=useState("");
    const [locality,setLocality]=useState("");
    const [timezone,setTimezone]=useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    let id="";
    useEffect(()=>{
        if(localStorage.getItem('customer-info')){
            if(JSON.parse(localStorage.getItem('customer-info')).fladdress){
                navigate("/customer/register/step3");
            }else{
                id=JSON.parse(localStorage.getItem('customer-info')).customer_id;
            }
        }else{
            navigate("/customer/register/step1");
        }
    });    

    function registerFormstep2(event) {
        event.preventDefault();
        let cid=id
        let regusterData = { addressFL, addressSL, city, state, country, zipcode, locality, timezone };   
        dispatch(registerstep2Customer({cid,regusterData})).then((result) => {            
            if (result.payload.error == "") {
                navigate("/customer/register/step3");
            }
        });
    };
    const  skipStep = () =>{
        navigate("/customer/dashboard");
    }    

    return (
        <>
            <RegStep StepValue={2} />
            <div className="regform-cont">
                <form id="registerForm2" onSubmit={(event) => registerFormstep2(event)}>
                    <div className='myRow inputrow'>
                        <div className='col-md-12'>
                            <div className="form__group field">
                                <input type="text" className="form__field" placeholder="Enter Address First Line" onChange={(e) => setAddressFL(e.target.value)} id="addressfl" required />
                                <label className="form__label">Address First Line</label>
                            </div>
                        </div>
                    </div>
                    <div className='myRow inputrow'>
                        <div className='col-md-12'>
                            <div className="form__group field">
                                <input type="text" className="form__field" placeholder="Enter Address Second Line" onChange={(e) => setAddressSL(e.target.value)} id="addresssl" required />
                                <label className="form__label">Address Second Line</label>
                            </div>
                        </div>
                    </div>
                    <div className='myRow inputrow'>
                        <div className='col-md-6'>
                            <div className="form__group field">
                                <input type="text" className="form__field" placeholder="Enter City" onChange={(e) => setCity(e.target.value)} id="city" required />
                                <label className="form__label">City</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form__group field">
                                <input type="text" className="form__field" placeholder="Enter State" onChange={(e) => setState(e.target.value)} id="state" required />
                                <label className="form__label">State</label>
                            </div>
                        </div>
                    </div>
                    <div className='myRow inputrow'>
                        <div className='col-md-6'>
                            <div className="form__group field">
                                <input type="text" className="form__field" placeholder="Enter Country" onChange={(e) => setCountry(e.target.value)} id="country" required />
                                <label className="form__label">Country</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form__group field">
                                <input type="text" className="form__field" placeholder="Enter Zipcode" onChange={(e) => setZipcode(e.target.value)} id="zipcode" required />
                                <label className="form__label">Zipcode</label>
                            </div>
                        </div>
                    </div>
                    <div className='myRow inputrow'>
                        <div className='col-md-6'>
                            <div className="form__group field">
                                <input type="text" className="form__field" placeholder="Enter Locality" onChange={(e) => setLocality(e.target.value)} id="locality" required />
                                <label className="form__label">Locality</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form__group field">
                                <input type="text" className="form__field" placeholder="Enter Timezone" onChange={(e) => setTimezone(e.target.value)} id="timezone" required />
                                <label className="form__label">Timezone</label>
                            </div>
                        </div>
                    </div>

                    <div className='myRow inputrow'>
                        <div className='col-md-6'>
                            <button type="submit" className="mynextbtn btn btn-success">Next</button>
                        </div>
                        <div className='col-md-6'>
                            <button type="button" className="myskipbtn btn btn-success" onClick={skipStep}>Skip</button>
                        </div>
                    </div>

                </form>
            </div>
        </>
    )
}
export default RegisterStep2