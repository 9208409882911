import '../../../css/login.css'
import '../../../css//formcontrol.css';
import logotitle from '../../../img/Parental_Care_India_bg.png';
import banklogo from '../../../img/bank-50.png';
import billlogo from '../../../img/bill-64.png';
import cartlogo from '../../../img/fast-cart-48.png';
import homelogo from '../../../img/home-48.png';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginCustomer } from '../../../redux/customerAuth';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import LoaddingRound from '../../LoadingRound';

function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    let [errorMsg, setErrorMsg] = useState("");

    const { loading, error } = useSelector((state) => state.customer);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function loginForm(event) {
        event.preventDefault();
        let loginDatas = { email, password };
        dispatch(loginCustomer(loginDatas)).then((result) => {
            if (result.payload.error === "") {
                navigate("/customer/dashboard");
            } else {
                setErrorMsg(result.payload.error);
            }
        });
    };
    useEffect(()=>{
        if(localStorage.getItem('customer-info')){
            navigate("/customer/dashboard");
        }
    }, [])

    return (
        <>
            <Header />
            <div className="total-body myRow bg_lightgray">
                <div className="login-cont myRow">

                    <div className="col-md-6 login-form">
                        <div className="cont-details">
                            <div className="heading-cont">LogIn</div>
                            <div className="body-cont" id="login">
                                <form id="loginForm" onSubmit={(event) => loginForm(event)}>
                                    <div className="form__group field">
                                        <input type="email" className="form__field" placeholder="Enter Email" id="useremail" required onChange={(e) => setEmail(e.target.value)} />
                                        <label className="form__label">Email</label>
                                    </div>
                                    <div className="form__group field">
                                        <input type="password" className="form__field" placeholder="Enter Password" id="password" required onChange={(e) => setPassword(e.target.value)} />
                                        <label className="form__label">Password</label>
                                    </div>
                                    {
                                        error && (<div className="myRow"><div className='login_error'>{error}</div></div>)
                                    }
                                    <div className="myRow"><div className='login_error'>{errorMsg}</div></div>
                                    <button type="submit" className="myloginbtn">Login <i className="fas fa-arrow-right"></i></button>
                                </form>
                                <div className="text-cont">Not join yet ! <Link to="/customer/register/step1">Join with us</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 login-details">
                        <div className="cont-details">
                            <div className="heading-cont">
                                <center><img src={logotitle} alt='title' /></center>
                            </div>
                            <div className="body-cont">
                                <div className="col-details-cont"><img src={cartlogo} alt="Shopping (Veg / Grocery / Household Items)" /> Shopping (Veg / Grocery / Household Items)</div>
                                <div className="col-details-cont"><img src={homelogo} alt="Home Visits for sundry needs" /> Home Visits for sundry needs</div>
                                <div className="col-details-cont"><img src={banklogo} alt="Bank / Post Office / Mediclaim / other official jobs" /> Bank / Post Office / Mediclaim / other official jobs</div>
                                <div className="col-details-cont"><img src={billlogo} alt="Utility Bill Payment (Elec.Bill / Tel Bill / Tax Bill etc.)" /> Utility Bill Payment (Elec.Bill / Tel Bill / Tax Bill etc.)</div>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
            {
                loading ? <LoaddingRound /> : ""
            }
        </>
    )
}
export default Login