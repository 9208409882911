import TopNav from "../../layout/TopNav";
import '../../../../css/customerlayout.css';
import '../../../../css/visitrequest.css';
import '../../../../css/mainformcontrol.css';
import SideMenu from "../../layout/SideMenu";
import HeaderNav from "../../layout/HeaderNav";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getVisitRequest, updateVisitRequest } from "../../../../redux/visitRequest";
function EditVisitRequest() {

    const [requestFor, setRequestFor] = useState("");
    const [emergency, setEmergency] = useState("no");
    const [visitDate, setVisitDate] = useState("");
    const [visitTime, setVisitTime] = useState("");
    const [serviceType, setServiceType] = useState("");
    const [preferedAsso, setPreferedAsso] = useState("");
    const [preferedAssoid, setPreferedAssoId] = useState("0");
    const [requestDetails, setRequestDetails] = useState("");
    const [additinalNote, setAdditinalNote] = useState("");
    let status;
    let { vistid } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [singleRequests, setSingleRequests] = useState([]);

    function capitalEachword(mySentence) {
        const words = mySentence.split(" ");
        for (let i = 0; i < words.length; i++)
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        return words.join(" ");
    }

    function blankRequest(event) {
        event.preventDefault();
    };
    function setCheckBox(event) {
        if (event.target.checked == true) {
            setEmergency("yes");
        } else {
            setEmergency("no");
        }
    }
    function updateRequest(type) {
        status = type;
        editVisitRequest();
    };

    function editVisitRequest() {
        let visit_id = vistid;
        let visitrequestData = { requestFor, emergency, visitDate, visitTime, serviceType, preferedAsso, requestDetails, additinalNote, status, preferedAssoid };
        dispatch(updateVisitRequest({ visit_id, visitrequestData })).then((result) => {
            if (result.payload.error == "") {
                navigate("/customer/visit-request");
            }
        });
    }

    useEffect(() => {
        dispatch(getVisitRequest(vistid)).then((result) => {
            if (result.payload.error === "") {
                let redata = result.payload.data[0];
                setSingleRequests(redata);

                setRequestFor(redata.request_for);
                setEmergency(redata.emergency);
                setVisitDate(redata.visit_date);
                setVisitTime(redata.visit_time);
                setServiceType(redata.servicetype);
                setPreferedAsso(redata.prefered_asso);
                setRequestDetails(redata.task_details);
                setAdditinalNote(redata.aditianalnote);
                status = redata.status;
            }
        });
    }, []);

    return (
        <>
            <div className="totalbody">
                <div className="left-sideSection">
                    <SideMenu Options="Visit Request" Suboptions="Edit Request" />
                </div>
                <div className="right-sideSection">
                    <TopNav />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Visit Request", "Edit Request"]} />
                        <div className="fullformdetails big-panel">
                            {
                                (singleRequests != "") ?
                                    <form id="addnewRequest" onSubmit={(event) => blankRequest(event)}>
                                        <div className="input-formtitle">
                                            Edit the request of - {vistid}
                                        </div>
                                        <div className="myRow">
                                            <div className="col-md-5 offset-md-1">
                                                <div className="input-formgroup">
                                                    <label className="input-label">Request For</label>
                                                    <input defaultValue={singleRequests.request_for} className="input-cell" required="required" id="requestfor" type="text" placeholder="Request For" onChange={(e) => setRequestFor(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="input-formgroup">
                                                    <label className="input-label">Curent Status</label>
                                                    <input defaultValue={capitalEachword(singleRequests.status)} className="input-cell" readOnly required="required" id="requestfor" type="text" placeholder="Request For" onChange={(e) => setRequestFor(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="myRow">
                                            <div className="col-md-5 offset-md-1">
                                                <div className="input-formgroup">
                                                    <label className="input-label">Is this an Emergency</label>
                                                    <label className="switch">
                                                        <input type="checkbox" onClick={(e) => setCheckBox(e)} id="emergency" defaultChecked={(singleRequests.emergency === "yes") ? true : false} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="myRow justify-content-md-center">
                                            <div className="col-md-5">
                                                <div className="input-formgroup">
                                                    <label className="input-label">Visit Date</label>
                                                    <input defaultValue={singleRequests.visit_date} className="input-cell" required="required" id="visitdate" type="date" placeholder="Visit Date" onChange={(e) => setVisitDate(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="input-formgroup">
                                                    <label className="input-label">Visit Time</label>
                                                    <input defaultValue={singleRequests.visit_time} className="input-cell" required="required" id="visittime" type="time" placeholder="Visit Time" onChange={(e) => setVisitTime(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="myRow justify-content-md-center">
                                            <div className="col-md-5">
                                                <div className="input-formgroup">
                                                    <label className="input-label">Service Types</label>
                                                    <input defaultValue={singleRequests.servicetype} className="input-cell" required="required" id="servicetype" type="text" placeholder="Service Type" onChange={(e) => setServiceType(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="input-formgroup">
                                                    <label className="input-label">Preferred Associate</label>
                                                    <input defaultValue={singleRequests.prefered_asso} className="input-cell" id="prefredasso" type="text" placeholder="Preferred Associate" onChange={(e) => setPreferedAsso(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="myRow justify-content-md-center">
                                            <div className="col-md-5">
                                                <div className="input-formgroup">
                                                    <label className="input-label">Request Details</label>
                                                    <textarea defaultValue={singleRequests.task_details} className="input-cell" required="required" id="requestdetails" type="text" placeholder="Request Details" onChange={(e) => setRequestDetails(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="input-formgroup">
                                                    <label className="input-label">Additional Notes</label>
                                                    <textarea defaultValue={singleRequests.aditianalnote} className="input-cell" required="required" id="addnotes" type="text" placeholder="Additional Notes" onChange={(e) => setAdditinalNote(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="myRow justify-content-md-end">
                                            {
                                                (singleRequests.status === "draft") ?
                                                    <>
                                                        <div className="col-md-2">
                                                            <input type="button" value="Complete Request" className="my-button bg-green" onClick={(e) => updateRequest("in progress")} />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <input type="button" value="Update as Draft" className="my-button bg-yellow" onClick={(e) => updateRequest("draft")} />
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="col-md-2">
                                                            <input type="button" value="Update Request" className="my-button bg-green" onClick={(e) => updateRequest(singleRequests.status)} />
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </form>
                                    :
                                    <div className="loading-gif">Loading...</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditVisitRequest;