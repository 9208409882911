import { configureStore } from "@reduxjs/toolkit";
import customerReducer from "../redux/customerAuth";
import visitRequestReducer from "../redux/visitRequest";

const store = configureStore({
    reducer:{
        customer: customerReducer,
        visitRequest: visitRequestReducer,
    }
});

export default store;