import { man_1_rb, man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb, lady_1_rb, lady_2_rb, lady_3_rb } from '../../../img/customeravatarimage';
function TopNav() {

    const customeruser = JSON.parse(localStorage.getItem('customer-info'));
    const custMale = [man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb];
    const custFemale = [lady_1_rb, lady_2_rb, lady_3_rb];
    const custAll = [man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb, lady_1_rb, lady_2_rb, lady_3_rb];
    let profilePic;
    
    if (customeruser.gender == "male") {
        profilePic = custMale[Math.floor(Math.random() * custMale.length)];
    } else if (customeruser.gender == "female") {
        profilePic = custFemale[Math.floor(Math.random() * custFemale.length)];
    } else {
        profilePic = custAll[Math.floor(Math.random() * custAll.length)];
    }

    const logout = () => {
        localStorage.clear();
    }

    return (
        <>
            <div className="topNavBar">
                <div className="greetType">
                    Welcome<br />
                    <span>Good Morning</span>
                </div>
                <div className="rightPart">
                    <div className="optionItem"><div className="chat-icon"><i className="fas fa-comment-dots"></i><span className="mark-num"></span></div></div>
                    <div className="optionItem"><div className="notification-icon"><i className="fas fa-bell"></i><span className="mark-num"></span></div></div>
                    <div className="cusName">{customeruser.fname} {customeruser.lname}</div>
                    <div className="optionItem">
                        <img src={profilePic} alt="Profile Pic" className='profileimg' />
                    </div>
                </div>
            </div>
        </>
    );
}
export default TopNav;