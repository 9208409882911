import React, { useEffect, useState } from "react";
import RegStep from "./RegStep"
import { useDispatch } from "react-redux";
import { registerstep1Customer } from "../../../redux/customerAuth";
import {useNavigate} from "react-router-dom";


function RegisterStep1() {

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    let [disableButton, setdisableButton] = useState(true);
    let [disableField, setdisableField] = useState(true);
    let [errorMsg, setErrorMsg] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    function checkPassword(data) {
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;
        let errMsg="";
        setdisableField(true);
        if (!data.match(uppercaseRegExp)) 
            errMsg="At least one Uppercase\n";
        if (!data.match(lowercaseRegExp)) 
            errMsg+="At least one Lowercase\n";
        if (!data.match(digitsRegExp)) 
            errMsg+="At least one digit\n";
        if (!data.match(specialCharRegExp))
            errMsg+="At least one Special Characters\n";
        if (!data.match(minLengthRegExp))
            errMsg+="At least minumum 8 characters\n";
        setErrorMsg(errMsg);
        if(errMsg==""){
            setPassword(data);
            setdisableField(false);
        }
    }
    function checkRePassword(data) {
        if (data == password) {
            setdisableButton(false);
        } else {
            setdisableButton(true);
        }
    }

    function registerForm(event) {
        event.preventDefault();
        let regusterData = { gender, email, password, lastName, middleName, firstName };
        dispatch(registerstep1Customer(regusterData)).then((result) => {
            if (result.payload.error == "") {
                navigate("/customer/register/step2");
            }
        });
    };

    useEffect(()=>{
        if(localStorage.getItem('customer-info')){
            navigate("/customer/dashboard");
        }
    }, [])

    return (
        <>
            <RegStep StepValue={1} />
            <div className="regform-cont">
                <form id="registerForm" onSubmit={(event) => registerForm(event)}>
                    <div className='myRow inputrow'>
                        <div className='col-md-6'>
                            <div className="form__group field">
                                <input type="text" className="form__field" placeholder="Enter First Name" onChange={(e) => setFirstName(e.target.value)} id="firstname" required />
                                <label className="form__label">First Name</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form__group field">
                                <input type="text" className="form__field" placeholder="Enter Middle Name" onChange={(e) => setMiddleName(e.target.value)} id="middlename" />
                                <label className="form__label">Middle Name</label>
                            </div>
                        </div>
                    </div>
                    <div className='myRow inputrow'>
                        <div className='col-md-6'>
                            <div className="form__group field">
                                <input type="text" className="form__field" placeholder="Enter Last Name" onChange={(e) => setLastName(e.target.value)} id="lastname" required />
                                <label className="form__label">Last Name</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form__group field">
                                <select className="form__field" onChange={(e) => setGender(e.target.value)} id="gender" required defaultValue={""}>
                                    <option value="" disabled >Select any one</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="transgender">Transgender</option>
                                </select>
                                <label className="form__label">Gender</label>
                            </div>
                        </div>
                    </div>
                    <div className='myRow inputrow'>
                        <div className='col-md-12'>
                            <div className="form__group field">
                                <input type="email" className="form__field" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} id="email" required />
                                <label className="form__label">Email</label>
                            </div>
                        </div>
                    </div>
                    <div className='myRow inputrow'>
                        <div className='col-md-12'>
                            <div className="form__group field">
                                <input type="password" className="form__field" placeholder="Enter Password" onChange={(e) => checkPassword(e.target.value)} id="password" required />
                                <label className="form__label">Password</label>
                            </div>
                        </div>
                    </div>
                    <div className="myRow password_error">
                       { errorMsg.split('\n').map((item, i) => (item!="")?<p>{item}</p>:"") }
                    </div>
                    <div className='myRow inputrow'>
                        <div className='col-md-12'>
                            {(disableField) ?
                                <div className="form__group field">
                                    <input type="password" className="form__field" placeholder="ReEnter Password" onChange={(e) => checkRePassword(e.target.value)} id="repassword" required disabled />
                                    <label className="form__label">Reenter Password</label>
                                </div> :
                                <div className="form__group field">
                                    <input type="password" className="form__field" placeholder="ReEnter Password" onChange={(e) => checkRePassword(e.target.value)} id="repassword" required />
                                    <label className="form__label">Reenter Password</label>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='myRow inputrow'>
                        {(disableButton) ? <button type="submit" className="myregisterbtn btn btn-success" disabled>Join Us</button> : <button type="submit" className="myregisterbtn btn btn-success">Join Us</button>}
                    </div>

                </form>
            </div>
        </>
    )
}
export default RegisterStep1